@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import '@kit/vendors/antd/style';
@import '@kit/vendors/bootstrap/style';
@import '@kit/vendors/perfect-scrollbar/style';
@import '@kit/vendors/nprogress/style';
@import '@kit/vendors/chartist/style';
@import '@kit/vendors/chartist-tooltip-plugin/style';
@import '@kit/vendors/jvectormap/style';
@import '@kit/vendors/react-sortable-tree/style';
@import '@kit/vendors/react-draft-wysiwyg/style';

@import '@kit/core/style'; // Clean UI KIT styles
@import '@airui/styles/style'; // Clean UI styles


/* customer css by ball */
.thumbnail {
    display: block;
    position: relative;
}
.thumbnail h2 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* Container holding the image and the text */
  .container-relative, .image-container {
    position: relative;
    text-align: center;
    color: white;
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }

  /* Bottom right text */
  .bottom-center {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-center2 {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bottom-center3 {
    position: absolute;
    bottom: -55px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  


  .player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
  }
  
  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }


  .internet-error {
    height: 60px;
    background: #ff8100;
    margin-top: 0;
    font-size: 20px;
  }
  
  .internet-error p {
    text-align: center;
    font-size: 25px;
    line-height: 60px;
    color: #fff;
    margin: 0;
  }



  // ################################## organize tree ##################################
  .org-tree ul {
    padding-top: 20px;
    position: relative;
  
    transition: all 0.5s;
  }
  
  .org-tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  /*We will use ::before and ::after to draw the connectors*/
  
  .org-tree li::before,
  .org-tree li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
  }
  .org-tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
  }
  
  .org-tree li:only-child::after,
  .org-tree li:only-child::before {
    display: none;
  }
  
  .org-tree li:only-child {
    padding-top: 0;
  }
  
  .org-tree li:first-child::before,
  .org-tree li:last-child::after {
    border: 0 none;
  }
  .org-tree li:last-child::before {
    border-right: 1px solid #ccc;
  }
  .org-tree li:last-child::before {
    border-radius: 0 5px 0 0;
  }
  .org-tree li:first-child::after {
    border-radius: 5px 0 0 0;
  }
  
  .org-tree ul ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }
  
  .org-tree li a {
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
  
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .org-tree li a:hover,
  .org-tree li a:hover + ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
  }
  
  .org-tree li a:hover + ul li::after,
  .org-tree li a:hover + ul li::before,
  .org-tree li a:hover + ul::before,
  .org-tree li a:hover + ul ul::before {
    border-color: #94a0b4;
  }

  // ################################## organize tree end ##################################